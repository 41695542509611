import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UniqueRechargeComponent } from "./unique-recharge.component";

export const createUniqueRechargeRootRoute = "unique-recharge";

const routes: Routes = [
  {
    component: UniqueRechargeComponent,
    path: "",
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UniqueRechargeRoutingModule {}
