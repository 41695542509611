import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { IAccount, IAccountsResponseDetails } from "@app/models/accounts";
import { IUserInfo, IUserInfoDataResult } from "@app/models/user-info";
import { environment } from "@environment";
import { CommonsService } from "@shared/services/commons.service";
import { InputComponent } from "@theme/components/input/input.component";
import { PaymentComponent } from "@theme/components/payment/payment.component";
import { SnackMessageOptions } from "@theme/components/snack-message/snack-message.component";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ICreateTransactionRequest } from "../../models/transaction.interface";
import { CreateTransactionFacade } from "../../store/facade/create-transaction.facade";
/**
 * Recharge Component
 */
@Component({
  selector: "fp-recharge-transaction",
  templateUrl: "./recharge.component.html",
  styleUrls: ["./recharge.component.scss"],
})
export class RechargeComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpRechargeTransaction = "fp-recharge-transaction";
  notifyChangeModality: TemplateRef<any>;
  @ViewChild("inputAmount") inputAmount: InputComponent;
  formTopUp: UntypedFormGroup;
  minAmount: number = Number(environment.pse.minimumAmount);
  typeMessage: SnackMessageOptions = "infoOnlyDescription";
  TopUpErrorMessages = {
    error: [
      { type: "required", message: "form_errors.field.required" },
      { type: "pattern", message: "form_errors.field.pattern" },
      { type: "amountInvalid", message: "form_errors.field.min_amount" },
    ],
  };

  accounts: IAccountsResponseDetails[] = [];
  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;
  @ViewChild("accountSelect") accountSelect: MatSelect;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedAccountType: string;
  selectedAccountId: string;
  selectedAccount: IAccountsResponseDetails;
  entitySelected: string;
  viewMessageError: boolean;
  timeOut: any;
  minimumAmount: any;
  clientId: string = "";
  userId: string = "";
  title: string = "";
  topUp: string = "";
  time: string = "";
  minAmountPos: any = {};
  minAmountPre: any = {};
  temporalTransactionObject: any = {};
  errorCode: string = "";

  /**
   * Construtor
   * @param snackBar MatSnackBar
   * @param storageService StorageService
   * @param appFacade AppFacade
   * @param window Window
   * @param pseService PseService
   */
  constructor(
    private snackBar: MatSnackBar,
    private appFacade: AppFacade,
    private commonsService: CommonsService,
    private transactionFacade: CreateTransactionFacade,
  ) {}

  /**
   * Init state to component
   */
  async ngOnInit(): Promise<any> {
    this.loadUserInfo();
    await this.getAccounts();
    this.initForm();
  }

  initForm(): void {
    this.formTopUp = new UntypedFormGroup({
      amount: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9.$ ]+$"),
        Validators.minLength(7),
      ]),
      account: new UntypedFormControl(this.accounts[0].accountId, [
        Validators.required,
      ]),
    });
  }
  /**
   * Destroy component
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.snackBar.dismiss();
  }
  /**
   * Selection Bank Change
   * @param entitySelected Entity Selected
   */
  onChangeBank(entitySelected: string) {
    this.entitySelected = entitySelected;
  }

  /**
   * get accounts info
   */
  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      .pipe(take(1))
      .toPromise();

    this.accounts = promiseAccounts.data.filter((x) => x.accountType);
  }

  /**
   * Create Transaction Recharge
   */
  async createTransaction(): Promise<void> {
    pageEvent(
      "click-btn-continue-recharge-transaction",
      "/transactions/recharge",
      "Init recharge transaction",
    );

    const formattedAmount = this.formTopUp.controls.amount.value
      .replace(/\./g, "")
      .replace("$ ", "");

    const account = this.formTopUp.controls.account.value;

    const transactionPayload: ICreateTransactionRequest = {
      totalAmount: Number(formattedAmount),
      transactionType: "recarga facilpass",
      transactionTypeData: {
        personId: this.userId,
        account,
      },
    };

    this.transactionFacade.CreateTransaction(transactionPayload);
    this.appFacade.getCities();
    try {
      this.appFacade.setLoaderShow({
        type: "GENERAL",
      });
      await this.transactionFacade.waitForTransactionSuccess().toPromise();
      this.appFacade.setLoaderHide();
      this.commonsService.navigate("/transactions/unique-recharge");
    } catch (error) {}
  }

  /**
   * Hide notifycation
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }
  /**
   * Function to validate Amount
   * @param val value to check with minimun amount
   */
  validateAmount(val: string): void {
    if (!val) {
      this.formTopUp.controls.amount.setErrors({ required: true });
      return;
    }
    let cleanVal = val.split("$")[1];
    if (cleanVal?.length > 1) {
      cleanVal = cleanVal.replace(/\./g, "");
      if (Number(cleanVal) < this.minAmount) {
        this.formTopUp.controls.amount.setErrors({ amountInvalid: true });
        this.formTopUp.controls.amount.markAsTouched();
        this.formTopUp.controls.amount.markAsDirty();
        pageEvent(
          "minimun-amount-error",
          "/transactions/recharge",
          "error-monto-minimo",
        );
      } else {
        this.viewMessageError = false;
      }
      this.inputAmount.updateInput();
    } else {
      this.formTopUp.controls.amount.setErrors({ pattern: true });
    }
  }
  /**
   * Function to validate Amount to buy Tag
   * @param val value to check with minimun amount
   */
  validateAmountTag(val: string): void {
    if (!val) {
      return;
    }
    let cleanVal = val.trim().split("$")[1];
    if (cleanVal?.length > 1) {
      cleanVal = cleanVal.replace(/\./g, "");
      Number(cleanVal) < this.minAmount
        ? (this.viewMessageError = true)
        : (this.viewMessageError = false);
    }
  }

  loadAmountValues(serviceInfo: any, type: string) {
    const amount = Number(serviceInfo.montoMinimo);
    const time =
      serviceInfo.tiempoActualizacionPeajes > 1
        ? `${serviceInfo.tiempoActualizacionPeajes} horas`
        : `${serviceInfo.tiempoActualizacionPeajes} hora`;
    this.time = this.minAmountPre.time = time;
    this.minAmount = this.minAmountPre.amount = amount;
  }

  loadUserInfo(): void {
    this.selectUserInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IUserInfo) => {
        if (action && action.data !== null) {
          const userInfo: IUserInfoDataResult = action.data;
          this.userId = userInfo.userClientInfo[0].userId;
        }
        if (action && action.error) {
          this.commonsService.navigate("error");
        }
      });
  }

  setRechargeText() {
    this.title = "title_" + this.selectedAccountType;
    this.topUp = "top_up_" + this.selectedAccountType;
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectUserInfo$(): Observable<IUserInfo> {
    return this.appFacade.selectUserInfo$;
  }
}
