import { CreateTransactionsFeatureName } from "@app/modules/transactions/store/state/create-transaction.state";
import { environment } from "@environment";
import { ActionReducer, INIT } from "@ngrx/store";
import { SignInFeatureName } from "@signin/store/state/signin.state";
import { DocumentsFeatureName } from "@singup/store/state/documents.state";
import { RegisterFeatureName } from "@singup/store/state/register.state";
import { TagFeatureName } from "@tag/store/state/tag.state";
import { PaymentFeatureName } from "@theme/components/payment/store/state/payment.state";
import { TransactionsFeatureName } from "@transactions/modules/status/store/state/statusTransaction.state";
import { VehiclesFeatureName } from "@vehicles/store/state/vehicles.state";
import CryptoJS from "crypto-js";
import { accountsFeatureName } from "../state/accounts.state";
import { addressFeatureName } from "../state/address.state";
import { IdentificationTypesFetureName } from "../state/app.state";
import { citiesFeatureName } from "../state/cities.state";
import { customerInfoFeatureName } from "../state/customer-info.state";
import { modulesFeatureName } from "../state/modules.state";
import { userInfoFeatureName } from "../state/user-info.state";
// eslint-disable-next-line jsdoc/check-alignment, jsdoc/check-indentation, jsdoc/newline-after-description
/** reducers to persist */
const config: string[] = [
  DocumentsFeatureName,
  RegisterFeatureName,
  TagFeatureName,
  PaymentFeatureName,
  VehiclesFeatureName,
  SignInFeatureName,
  TransactionsFeatureName,
  IdentificationTypesFetureName,
  modulesFeatureName,
  citiesFeatureName,
  accountsFeatureName,
  customerInfoFeatureName,
  userInfoFeatureName,
  addressFeatureName,
  CreateTransactionsFeatureName,
];
export const hydrationMetaReducer = (
  reducer: ActionReducer<any>,
): ActionReducer<any> => {
  return (state, action) => {
    const nextState = reducer(state, action);
    const valores = {};
    let exist = false;
    // eslint-disable-next-line guard-for-in
    for (const key in sessionStorage) {
      if (
        key.includes("state-") === true &&
        sessionStorage.getItem(key) !== null
      ) {
        valores[key.slice(6)] = JSON.parse(
          CryptoJS.AES.decrypt(
            sessionStorage.getItem(key),
            environment.facilpasskey.trim(),
          ).toString(CryptoJS.enc.Utf8),
        );
        exist = true;
      }
    }

    if (action.type === INIT) {
      if (exist) {
        try {
          return { ...nextState, ...valores };
        } catch {
          sessionStorage.removeItem("state");
          for (const key in sessionStorage) {
            if (
              key.includes("state-") &&
              sessionStorage.getItem(key) !== null
            ) {
              sessionStorage.removeItem(key);
            }
          }
        }
      }
    }
    config.forEach((value) => {
      if (value in nextState) {
        const valor = JSON.stringify(nextState[value]);
        const encrypt = CryptoJS.AES.encrypt(
          valor,
          environment.facilpasskey.trim(),
        ).toString();
        sessionStorage.setItem("state-" + value, encrypt);
      }
    });
    return nextState;
  };
};
