import { ICreateTransaction } from "../../models/transaction.interface";

export const CreateTransactionsFeatureName = "CreateTransactionReducer";

export type CreateTransactionsModuleState = Readonly<ICreateTransaction>;

export const initialPaymentState: ICreateTransaction = {
  payment: null,
  paymentMethods: [],
  token: "",
};
