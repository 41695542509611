<div class="recarga-item">
    <div class="recarga-info">
        <h3 class="recarga-titulo">
            {{transaction.transactionType}}
        </h3>
        <p class="recarga-fecha">
            {{ transaction.confirmedDateFormatted }}
        </p>
        <span class="recarga-status" [ngClass]="getStatusCategory(transaction.statusView)">
            {{getStatusCategory(transaction.statusView) | translateStatus}}
        </span>
    </div>

    <div class="recarga-derecha">
        <p class="recarga-monto" [ngStyle]="{'--action-color': getStatusCategory(transaction.statusView) === 'approved' ? '#20710D': ''}">
            {{ transaction.totalAmount | currency : 'COP' : 'symbol' : '1.0-0'}}
        </p>
        <ng-container class="recarga-tarjeta">
            <ng-container [ngSwitch]="transaction.paymentMethod">
                <img *ngSwitchCase="'PSE'" src="assets/images/common/pse.svg" alt="logo pse">
            
                <ng-container *ngSwitchCase="'TC'">
                  <div *ngIf="getStatusCategory(transaction.statusView) !== 'pending'; else defaultCard">
                    <img [src]="getCardBrandImage(transaction.brand)" [alt]="transaction.brand">
                    <span class="tarjeta-numero" *ngIf="transaction.cardMask">***{{ transaction.cardMask }}</span>
                  </div>
                  <ng-template #defaultCard>
                    <img src="assets/images/transactions/credit-card-brands/gou.svg" alt="logo gou">
                  </ng-template>
                </ng-container>
              </ng-container>
            
        </ng-container>
    </div>
</div>