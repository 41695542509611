import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@app/modules/core/core.module";
import { ItemListTransactionsComponent } from "./item-list-transactions.component";

@NgModule({
  declarations: [ItemListTransactionsComponent],
  imports: [CommonModule, CoreModule],
  exports: [ItemListTransactionsComponent],
})
export class ItemListTransactionsModule {}
