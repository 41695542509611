import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "translateStatus",
})
export class TranslateStatusPipe implements PipeTransform {
  private successStatuses = ["approved", "confirmed"];
  private rejectedStatuses = ["rejected", "expired", "abandoned", "failed"];
  private pendingStatuses = [
    "created",
    "pending",
    "pendingprovider",
    "onprocess",
    "unconfirmed",
  ];

  private translations: { [key: string]: string } = {
    approved: "Aprobado",
    pending: "Pendiente",
    rejected: "Rechazado",
  };

  transform(value: string): string {
    const normalizedStatus = value.toLowerCase();

    let category: string = "unknown";

    if (this.successStatuses.includes(normalizedStatus)) {
      category = "approved";
    } else if (this.rejectedStatuses.includes(normalizedStatus)) {
      category = "rejected";
    } else if (this.pendingStatuses.includes(normalizedStatus)) {
      category = "pending";
    }

    return this.translations[category] || category;
  }
}
