import { Injectable } from "@angular/core";
import { select, Store, ActionsSubject } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, first } from "rxjs/operators";
import {
  ICreateTransaction,
  ICreateTransactionRequest,
} from "../../models/transaction.interface";
import * as PaymentActions from "../actions/create-transaction.actions";
import * as PaymentSelectors from "../selectors/create-transaction.selector";
import { CreateTransactionsModuleState } from "../state/create-transaction.state";

@Injectable({
  providedIn: "root",
})
export class CreateTransactionFacade {
  public selectCreateTransaction$: Observable<ICreateTransaction> =
    this.store.pipe(select(PaymentSelectors.selectCreateTransaction));

  constructor(
    private store: Store<CreateTransactionsModuleState>,
    private actionsSubject$: ActionsSubject, // Para escuchar acciones directamente
  ) {}

  /**
   * Despacha la acción para crear una transacción
   */
  public CreateTransaction(request: ICreateTransactionRequest): void {
    this.store.dispatch(PaymentActions.loadPaymentData(request));
  }

  /**
   * Escucha la acción de éxito para saber cuándo se ha completado la transacción
   */
  public waitForTransactionSuccess(): Observable<any> {
    return this.actionsSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          PaymentActions.TypeActions.createTransactionLoadedString,
      ), // Escucha solo la acción de éxito
      first(), // Toma solo la primera ocurrencia
    );
  }
}
