import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { creditCardTransactionsRootRoute } from "./modules/credit-card-transactions/credit-card-transactions-routing.module";
import { createRechargeRootRoute } from "./modules/recharge/recharge-routing.module";
import {
  createStatusTransactionRootRoute,
  createStatusTransactionRootRouteFreeTag,
} from "./modules/status/status-routing.module";
import { createUniqueRechargeRootRoute } from "./modules/unique-recharge/unique-recharge-routing.module";
import { uniqueRechargeValidateRootRoute } from "./modules/unique-recharge-validate/unique-recharge-validate-routing.module";

export const transactionsRootRoute = "transactions";

const routes: Routes = [
  {
    path: "",
    redirectTo: createRechargeRootRoute,
    pathMatch: "full",
  },
  {
    path: createRechargeRootRoute,
    loadChildren: () =>
      import("./modules/recharge/recharge.module").then(
        (m) => m.RechargeModule,
      ),
  },
  {
    path: createUniqueRechargeRootRoute,
    loadChildren: () =>
      import("./modules/unique-recharge/unique-recharge.module").then(
        (m) => m.UniqueRechargeModule,
      ),
  },
  {
    path: createStatusTransactionRootRoute,
    loadChildren: () =>
      import("./modules/status/status.module").then((m) => m.StatusModule),
  },
  {
    path: createStatusTransactionRootRouteFreeTag,
    loadChildren: () =>
      import("./modules/status/status.module").then((m) => m.StatusModule),
  },
  {
    path: uniqueRechargeValidateRootRoute,
    loadChildren: () =>
      import(
        "./modules/unique-recharge-validate/unique-recharge-validate.module"
      ).then((m) => m.UniqueRechargeValidateModule),
  },
  {
    path: creditCardTransactionsRootRoute,
    loadChildren: () =>
      import(
        "./modules/credit-card-transactions/credit-card-transactions.module"
      ).then((m) => m.CreditCardTransactionsModule),
  },
  {
    path: "**",
    redirectTo: createUniqueRechargeRootRoute,
    pathMatch: "full",
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
