<fp-card-body [template]="'secondTemplate'" class="fp-top-up" close="true">
    <ng-template>
        <div class="fp-container-movement">
            <h1 class="fp-h1 fp-list-title">{{ 'title' | translate }}</h1>
            <div *ngIf="!loading && !transactionError && transactions.length === 0" class="card-empty">
                <fp-item-list-empty iconEmpety="assets/images/movement/movement-empty.svg"
                    [descriptionEmpety]="'list_empty' | translate "></fp-item-list-empty>
            </div>
            <div class="p-relative" *ngIf="!loading && transactions.length >0">
                <div class="fp-card-movement fp-fadeIn" id="scroller" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" [class.is-mobile]="isMobile">

                    <fp-item-list-transactions *ngFor="let transaction of transactions; let indexItem = index"
                        [transaction]="transaction">
                    </fp-item-list-transactions>

                </div>
                <div *ngIf="lastEvaluatedKey !== ''" class="transactions-blur"></div>
            </div>
            <div *ngIf="(loading || loadingMore) && !transactionError" class="fp-card-movement skeleton ">
                <div class="card-fake" *ngFor="let item of [1,2,3]">
                    <div class="row">
                        <div class="mr-auto">
                            <div class="skeleton texts">
                            </div>
                            <div class="skeleton texts small">
                            </div>
                            <div class="skeleton texts small">
                            </div>
                        </div>
                        <div class="skeleton switch-fake"></div>
                        <div class="mr-auto second">
                            <div class="skeleton texts w-small">
                            </div>
                            <div class="skeleton texts w-small small">
                            </div>
                        </div>
                        <div class="skeleton card-arrow"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!loading && transactionError" class="notify-error fp-fadeIn">
                <fp-item-list-empty iconEmpety="assets/images/common/errorService.svg"
                    [descriptionEmpety]="'error.message' | translate " [notifyError]="'true'"></fp-item-list-empty>
                <div style="margin-top: 27px;"></div>
                <div class="notify-error-btn">
                    <button class="fp-btn-primary" type="submit" id="btn-list-create-vehicle">
                        {{ 'error.btn_agin' | translate }}
                    </button>
                </div>
            </div>


        </div>
        <div #elementForScroll></div>
    </ng-template>
</fp-card-body>