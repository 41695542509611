import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable()
export class DocumentsService {
  constructor(private http: HttpClient) {}
  /**
   * Service to get terms and conditions data
   */
  getTermsandConditions(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.prospect.init +
        environment.endpoints.prospect.specific.documents +
        `?document_status=true`,
      {
        params: new CustomHttpParams("api-pn-public-apiKey"),
      },
    );
  }
  /**
   * Specific endpoint de get habeas data
   */
  getHabeasData(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.prospect.init +
        environment.endpoints.prospect.specific.habeasdata +
        `?policy_enabled=true&policy_checks_enabled=true`,
      {
        params: new CustomHttpParams("api-pn-public-apiKey"),
      },
    );
  }
}
