export const environment = {
  hmr: false,
  production: false,
  swpwa: true,
  endpoints: {
    prospect: {
      init: "/prospects",
      specific: {
        identificationTypes: "/identificationtypes",
        validate: "/validate",
        documents: "/documents",
        habeasdata: "/habeasdata",
      },
    },
    password: {
      init: "/password",
      specific: { validate: "/validate" },
    },
    management: {
      init: "/management",
      specific: {
        dataparameters: "/dataparameters",
      },
    },
    vehicles: {
      core: "/core",
      init: "/vehicles",
      get: "/get",
      v2: "/v2",
      specific: {
        categories: "/categories",
        create: "",
        delete: "/delete",
        vehicles: "/vehicles",
      },
    },
    commons: {
      init: "/commons",
      specific: {
        cities: "/cities",
      },
    },
    vinculate: {
      init: "/vinculate",
    },
    tags: {
      init: "/tags",
      specific: {
        termsAndConditions: "/terms_and_conditions",
        types: "/types",
        shippingAddress: "/shipping_address",
        reservation: "/reservation",
        confirmation: "/confirmation",
        active: "/active",
        vehicle_tag: "/vehicle-tag",
        tag_no_cost: "/tag_no_cost",
      },
    },
    pse: {
      init: "/pse",
      specific: {
        banks: "/banks",
        transaction: "/transaction",
        statusTransaction: "/get/transaction?url=",
      },
    },
    recepit: {
      init: "/receipt",
      specific: {
        transactionId: "?transactionId=",
      },
    },
    dashboard: {
      init: "/dashboard?pageSizeVehicles=3&pageSizeMovements=3",
    },
    vehicle_tag: {
      init: "/vehicle-tag",
    },
    movements: {
      init: "/movements",
    },
    movementsDashboard: {
      init: "/movements_dashboard",
    },
    session: {
      init: "/session",
      specific: {
        login: "/login",
        logout: "/logout",
        refresh: "/refresh",
      },
    },
    autoenrollment: {
      init: "/autoenrollment",
      specific: {
        tag: "/tag",
      },
    },
    usersession: {
      init: "/usersession",
      specific: {
        signup: "/signup",
        login: "/login",
        refresh: "/refresh",
        logout: "/logout",
        forgotpassword: "/forgotpassword",
        confirmforgotpassword: "/confirmforgotpassword",
        confirm: "/confirm",
        resendconfirmationcode: "/resendconfirmationcode",
        admindeleteuser: "/admindeleteuser",
      },
    },
    customer_consultation: {
      init: "/customer_consultation",
    },
    user_customer: {
      init: "/user_customer",
    },
    accounts: {
      init: "/accounts",
      specific: {
        availableAccounts: "/get_available_accounts",
        rechargeFinancialProduct: "/recharge_financial_product",
        financialProducts: "/get_financial_products",
        associateProduct: "/associate_financial_product",
        createRefund: "/create_refund",
        createRefundPartial: "/create_refund_partial",
        updateRefundPartial: "/update_refund_partial",
        create: "/create",
      },
    },
    users: {
      init: "/users",
      specific: {
        addresses: "/addresses?id=",
        personId: "/coupons?personId=",
        couponId: "&couponId=",
      },
    },
    coreReports: {
      init: "/core",
      specific: {
        report: "/report",
        preSign: "/presignedUrl",
        consultReport: "/consultReport",
      },
    },
    fileReport: {
      init: "/dev",
      specific: {
        file: "/files/download",
      },
    },
    payments_gateway: {
      init: "/payments-gateway",
      specific: {
        transaction: "/transaction",
        updatePayment: "/payments",
        getTransactions: "/payments/transactions-by-status",
        paymentValidation: "/payment-validations",
      },
    },
    encryptedRequest: {
      init: "/security-core",
      specific: {
        publicKey: "/auth/publickey",
      },
    },
    analyticEvents: {
      init: "/core",
      specific: {
        analytic: "/analytic/events",
      },
    },
  },

  api_key: {
    publicPN: {
      back_url: "https://fap-toll-dev-api-public.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX19F9k9fztI8L8v8yzF8rRhjegV67vI7CmgAytMmZaYgbfZXLpRePII4HF5qzPMvLHvTzwSHvnYRMw==",
      specific: {
        users_api_key: "EJfpjw0f7p1lYbOqEdoiC7SEmlBDR5GL8M1Y6l8C",
      },
    },
    publicPJ: {
      back_url:
        "https://fap-toll-dev-api-public-enterprises.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1/Q051wso/cdp9twCE0+fw5WjOWJb0ZgGbH4BWzfHmBWZPthTHNstPGxTiXM4Y5XjNZI9YYpXFngw==",
    },
    vinculation_process: {
      back_url:
        "https://fap-toll-dev-api-vinculation-process.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1/Z5Ctf27vo7NCIXB9kKDlq38v59PMMRIAzgGmtBd52s4FHZMWsWQmZgH1cauFWisrqBksosKNGqQ==",
    },
    vehicles: {
      back_url: "https://fap-toll-dev-api-vehicles.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1+xZhLnKuqvbGFWbCu/GwD30g/ZZ3VPO18VTIQaws4mYi0Y1XCtLK8sV7bSjJouQGmP893Qepn4Zg==",
    },
    payments: {
      back_url: "https://fap-toll-dev-api-payments.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1+UWzodMgwWgyobNXJ7/T06nauuOUWg5s0NgE1KatL9XxQ8p4ScD5iXn+vusJ6+JQji3VAQc5YVEw==",
    },
    commons: {
      back_url: "https://fap-commons-dev-api-web-tags.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1+mUvR2aWCz1FSd9vUpDkJQcYmaVvHmEGUaEMABDiJWVrgVVXjeAHzXI52zTC9A5bM7TGICiT6Pfg==",
    },
    security: {
      back_url: "https://fap-toll-dev-api-security.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX18GMCEPSre4kaWlCkII4KqlZh1peVKypc51TQXvNfPbxHA2DK24cB64OS8jJqzyftwSqYbM6gGLVw==",
    },
    shared: {
      back_url:
        "https://fap-commons-dev-api-shared-services.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX18Tzdk9jnHzzvOgUIIGgwfk5LWdV8sNXj/jYtWW88hJBPuBMval7nm7RgFctFYVQbBj13KqAhjfbw==",
    },
    services: {
      back_url: "https://toll-services-facilpass-api-dev.avaldigitallabs.com",
      x_api_key_events_analytic: "jHAytFJsLfaHdbfKsLFGA7VxjWM2QnvD9u0VQAJT",
    },
  },
  facilpasskey: "F1p1c7lp1ssK3y",
  facilpasskeyPublic: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj+qTIt2Do5erh+8Uw1RyFgMp18Q8iQbcgcMqmomHmYhRTnWXAATIfFgJ8xXitZnZ+oWIo1MrzM8QllwY8i8lTHDbepTvFLNsm72wv8lEqicRO+wAGZdbTZhXGg7wVN2Cd56pjpug8wuboRAkH6FPFtOuQLs8GumyREE/qiOzXd0Afw6/P/JGX5/kShWl+9FGlyfunR+rmu9bu33tZkNxCa5h4ZwZ0CQp8x6smr5b58RzDmeo6jeJQ6aiTbVbJsoBpM37OEfxX9WxCYabbC33llAam8xy8YmfWt25mZIcQxC9HrfvcGr3rJ3w47w/fo4EeTKbWzUZ0HxlRqUAaxj+9wIDAQAB`,
  tealium: {
    account: "adl",
    profile: "facilpass",
    environment: "dev",
  },
  pse: {
    minimumAmount: "30000",
    hold: "2",
  },
  inactivityTime: 300000,
  keyngCache: "6LeCeTkaAAAAAB68AWaQWH-F_9GPbpe4M3KxB0iX",
  tagInstalationManual:
    "https://fap-toll-dev-documents.s3.us-east-2.amazonaws.com/pdf/ManualDispositivoElectronicoTAG.pdf",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
