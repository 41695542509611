import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CreditCardTransactionsComponent } from "./credit-card-transactions.component";

export const creditCardTransactionsRootRoute = "list";

const routes: Routes = [
  {
    component: CreditCardTransactionsComponent,
    path: "",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreditCardTransactionsRoutingModule {}
