import { Component, Input } from "@angular/core";
import { ViewCardTransaction } from "@app/modules/transactions/models/card-transactions";

@Component({
  selector: "fp-item-list-transactions",
  templateUrl: "./item-list-transactions.component.html",
  styleUrls: ["./item-list-transactions.component.scss"],
})
export class ItemListTransactionsComponent {
  @Input() transaction: ViewCardTransaction;
  constructor() {}

  getStatusCategory(status: string): string {
    const successStatuses = ["approved", "confirmed"];
    const rejectedStatuses = ["rejected", "expired", "abandoned", "failed"];
    const pendingStatuses = [
      "created",
      "pending",
      "pendingProvider",
      "onProcess",
      "unconfirmed",
    ];

    if (successStatuses.includes(status)) {
      return "approved";
    } else if (rejectedStatuses.includes(status)) {
      return "rejected";
    } else if (pendingStatuses.includes(status)) {
      return "pending";
    }

    return "unknown";
  }

  getCardBrandImage(brand: string): string {
    const brandImages = {
      visa: "assets/images/transactions/credit-card-brands/visa.svg",
      amex: "assets/images/transactions/credit-card-brands/amex.svg",
      master: "assets/images/transactions/credit-card-brands/master.svg",
      diners: "assets/images/transactions/credit-card-brands/diners.svg",
      default: "assets/images/transactions/credit-card-brands/gou.svg",
    };
    return brandImages[brand] || brandImages.default;
  }
}
