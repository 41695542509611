<fp-modal [typeModal]="'custom'" id="fp-gou-redirect-modal" [redirect]="true" (actionModal)="modalAction()"
    [btnAccept]="'Entendido'"[btnInfo]="'Ghost'">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">
                {{'modal_gou.title' | translate}}
            </h1>
            <p class="fp-payment-method-message">
                {{'modal_gou.description' | translate}}
            </p>
            <img src="assets/images/gou/gou-logotype.svg" alt="">
        </div>
    </ng-template>
</fp-modal>