<div class="fp-modal" [class.editInfo]="typeModal === 'editInfo'" [class.showConfirm]="typeModal === 'showConfirm'">
    <div class="fp-modal-content-body">
        <div class="fp-modal-body"
            [class.editInfo]="typeModal === 'editInfo' || typeModal === 'showConfirm' || typeModal === 'custom' || typeModal === 'avalPay'">
            <div class="fp-modal-decorador" [class.hidden]="hideDecorator"></div>
            <div class="fp-close-modal">
                <img src="assets/images/close/close.svg"
                    [class.hidden]="typeModal === 'showConfirm' && !showConfirm || hideClose" alt="cerrar modal"
                    (click)="hideClose?null:close()" id="close-{{id}}">
            </div>
            <ng-container *ngIf="typeModal === 'defaul'">
                <h2 class="fp-h1 fp-mdal-title">{{ title }}</h2>
            </ng-container>
            <div class="fp-modal-content">
                <ng-container [ngSwitch]="typeModal">
                    <ng-container *ngSwitchCase="'defaul'">
                        <div class="fp-modal-content-text" [class.big]="typeModalBig">
                            <ng-scrollbar class="fp-scrollbar" track="all" pointerEventsMethod="scrollbar">
                                <div class="fp-modal-text" [innerHtml]="innerHtmlContent">
                                </div>
                                <br>
                            </ng-scrollbar>
                        </div>
                        <div class="fp-modal-btns">
                            <div class="fp-modal-blur"></div>
                            <button class="fp-btn-primary" type="button" id="accept-{{id}}"
                                (click)="emitActionModal('accept')">
                                {{btnAccept}}
                            </button>
                            <button class="fp-btn-ghost" type="button" (click)="emitActionModal('downloadDocuments')"
                                id="download-{{id}}">
                                {{btnDownload}}
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'editInfo'">
                        <form class="fp-flex" novalidate [formGroup]="formEditInfo" (ngSubmit)="validForm()">
                            <img class="icon-modal" [src]="iconModal" alt="icon edit modal">
                            <h2 class="fp-h1"> {{titleEdit}} {{typeTextEdit}}</h2>
                            <fp-input #inputModal class="form-group" styleClass="form-group" labelClass="form-label"
                                [nameLabelTranslate]="labelTypeEdit" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true" [autoFocus]="true" inputClass="input-large"
                                [typeInput]="typeEdit" nameInput="generic" idInput="generic" [maxlength]="maxlength"
                                formControlName="generic" [inputValidationMessages]="editInfoModalMessages.error"
                                [formControl]="formEditInfo.controls['generic']" [regexp]="typeEditRegexp"
                                [matchRegexp]="typeEditmatchRegexp" [viewMessageError]="viewMessageError"
                                (onInput)="inputEvent($event, inputModal)">
                            </fp-input>
                            <div class="form-group-submit">
                                <button class="fp-btn-primary" type="submit" id="button-update-{{id}}"
                                    [disabled]="!formEditInfo.valid || formEditInfo.controls['generic'].value === infoGeneric">
                                    <span class="btn-content-text">
                                        Guardar
                                    </span>
                                </button>
                            </div>
                            <div class="space-modal editInfo"></div>
                        </form>
                    </ng-container>
                    <ng-container *ngSwitchCase="'showConfirm'">
                        <div class="fp-flex" [class.showConfirm]="typeModal === 'showConfirm'">
                            <img class="icon-modal" [src]="iconModalConfimr" alt="icon update modal">
                            <h1 class="fp-h1 fp-title-confirm">{{titleConfirm}}</h1>
                            <div class="fp-body-one showConfirm" [class.big]="typeModalBig"
                                [innerHtml]="innerHtmlTextConfirm"> </div>
                            <button class="fp-btn-primary" type="button" id="accept-update-{{id}}" *ngIf="btnAccept"
                                (click)="emitActionModal('accept')">
                                {{btnAccept}}
                            </button>
                            <button *ngIf="btnExit" class="fp-btn-secondary" type="button" id="exit-update-{{id}}"
                                (click)="emitActionModal('exit')">
                                {{btnExit}}
                            </button>
                            <div class="space-modal showConfirm"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'avalPay'">
                        <form class="fp-flex showConfirm" novalidate [formGroup]="formEditInfo">
                            <img class="icon-modal" [src]="iconModalConfimr" alt="icon update modal">
                            <h1 class="fp-h1 fp-title-confirm"></h1>
                            <div class="fp-body-one showConfirm" [class.big]="typeModalBig"
                                [innerHtml]="innerHtmlTextConfirm"> </div>

                            <fp-info-message
                                message="Recuerda que tu recarga aparecerá en el extracto de tu tarjeta de crédito como: 'Patrimonios Autónomo FIDUCORFICOL'"
                                typeClass="info" [showClose]="false"></fp-info-message>

                            <div class="container-input-nure">
                                <fp-input class="form-group" styleClass="form-group" labelClass="form-label"
                                    [nameLabelTranslate]="labelTypeEdit" viewSpaceInput="true"
                                    [inputClass]="'input-large'" nameInput="nure" idInput="nure"
                                    formControlName="generic" [formControl]="formEditInfo.controls['generic']"
                                    [readonly]="true" inputmode="numeric">
                                </fp-input>
                                <button #tooltip [matTooltip]="mostrarTooltip ? tooltipMensaje : ''"
                                    matTooltipPosition="above" class="btn-item-toogle btn-eye" type="button"
                                    (click)="copyText()">
                                    <img src="assets/images/common/icon-copiar.svg" alt="copiar">
                                </button>
                            </div>
                            <button class="fp-btn-primary" type="button" id="accept-update-{{id}}" *ngIf="btnAccept"
                                (click)="emitActionModal('accept')">
                                {{btnAccept}}
                            </button>
                            <button *ngIf="btnExit" class="fp-btn-secondary" type="button" id="exit-update-{{id}}"
                                (click)="emitActionModal('exit')">
                                {{btnExit}}
                            </button>
                            <div class="space-modal showConfirm"></div>
                        </form>
                    </ng-container>
                    <ng-container *ngSwitchCase="'custom'">
                        <ng-template *ngTemplateOutlet="tplRef">

                        </ng-template>
                        <button class="fp-btn-primary" type="button" id="accept-update-{{id}}" *ngIf="btnAccept"
                            (click)="!redirect?close():emitActionModal('accept')">
                            {{btnAccept}}
                        </button>

                        <button class="fp-btn-ghost blue" type="button" id="btnExample" *ngIf="btnInfo"
                            (click)="emitActionModal('info')">
                            {{btnInfo}}
                        </button>

                        <div class="space-modal custom"></div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="fp-modal-background"></div>