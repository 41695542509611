import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  /**
   * Constructor
   *
   * @param http hhtp Client
   */
  constructor(private http: HttpClient) {}
  getDashboardInfo(): Observable<any> {
    return this.http.get(
      environment.api_key.security.back_url +
        environment.endpoints.dashboard.init,
      {
        params: new CustomHttpParams("api-pn-security-apiKey-token"),
      },
    );
  }

  getPublicKey(): Observable<any> {
    const bodyKey = { timestamp: Date.now() };
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.encryptedRequest.init +
        environment.endpoints.encryptedRequest.specific.publicKey,
      bodyKey,
      {
        params: new CustomHttpParams("api-pn-security-apiKey-token"),
      },
    );
  }
}
