import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertsTypes } from "@app/modules/shared/models/alert-message.enum";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { NotifyService } from "@app/modules/shared/services/notify.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPaymentValidateResponse } from "../../models/transaction.interface";
import { StatusTransactions } from "../../models/transactions.enum";
import { TransactionsService } from "../../services/transactions.service";

@Component({
  selector: "fp-unique-recharge-validate",
  templateUrl: "./unique-recharge-validate.component.html",
  styleUrls: ["./unique-recharge-validate.component.scss"],
})
export class UniqueRechargeValidateComponent implements OnDestroy, OnInit {
  /** Snackbar message */
  @ViewChild("snackbarMsg", { static: true }) snackbarMsg: TemplateRef<any>;
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Status card reference */
  statusPaymentValidated: any;
  /** Status card validated enum */
  statusTransactions = StatusTransactions;
  /** Alerts types enum */
  alertsTypes = AlertsTypes;
  /** Account data */
  accountId: string = "";
  /** Brands cards */
  cardsBrands = {
    pse: "assets/images/common/pse.svg",
    visa: "assets/images/common/visa-logo.svg",
    master: "assets/images/common/master-card-logo.svg",
  };
  /** Status icons */
  statusIcons = {
    [StatusTransactions.APPROVED]: "assets/images/common/toast-success.svg",
    [StatusTransactions.PENDING]:
      "assets/images/common/filled-yellow-warning.svg",
    [StatusTransactions.REJECTED]: "assets/images/common/toast-error.svg",
    [StatusTransactions.FAILED]: "assets/images/common/toast-error.svg",
    [StatusTransactions.DECLINED]: "assets/images/common/toast-error.svg",
  };
  /** Titles */
  titles = {
    [StatusTransactions.APPROVED]: "Su recarga fue aprobada",
    [StatusTransactions.PENDING]: "Su recarga se encuentra en validación",
    [StatusTransactions.REJECTED]: "Su recarga fue rechazada",
    [StatusTransactions.FAILED]: "Su recarga fue rechazada",
    [StatusTransactions.DECLINED]: "Su recarga fue rechazada",
  };

  /**
   * Constructor
   * @param activatedRoute
   * @param transactionsService
   * @param notifyService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private transactionsService: TransactionsService,
    private commonsService: CommonsService,
    private notifyService: NotifyService,
  ) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const ref = params["id"];
      this.validatePaymentReference(ref);
    });
  }

  /**
   * Validate payment reference
   * @param ref
   */
  async validatePaymentReference(ref: string): Promise<void> {
    await this.transactionsService
      .validatePayment(ref)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IPaymentValidateResponse) => {
          this.statusPaymentValidated = response.payment;
          if (
            this.statusPaymentValidated?.statusProvider ===
            StatusTransactions.PENDING
          ) {
            this.notifyService.showNotify(this.snackbarMsg, "", null);
            setTimeout(() => {
              this.closeMessage();
            }, 5000);
          }
          if (
            this.statusPaymentValidated?.statusProvider ===
              StatusTransactions.PENDING ||
            this.statusPaymentValidated?.statusProvider ===
              StatusTransactions.APPROVED
          ) {
            localStorage.removeItem("transactionPayload");
          }
        },
        error: (error) => {},
      });
  }

  /**
   * Go back
   */
  goBack(): void {
    if (
      this.statusPaymentValidated?.statusProvider ===
        StatusTransactions.PENDING ||
      this.statusPaymentValidated?.statusProvider ===
        StatusTransactions.APPROVED
    ) {
      this.commonsService.navigate("/home");
    } else {
      this.commonsService.navigate("/transactions/recharge");
    }
  }

  getBrandImage(): string {
    const brand = this.statusPaymentValidated?.brand;
    if (brand && brand !== "EMPTY" && this.cardsBrands[brand]) {
      return this.cardsBrands[brand];
    }
    return "assets/images/common/credit-card-icon.svg";
  }

  /**
   * Hide notifycation
   */
  closeMessage(): void {
    this.notifyService.closeNotify();
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
