import { CommonModule, CurrencyPipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AutofocusDirective } from "./directives/autofocus-directive";
import { ValidatorRegExpDirective } from "./directives/validator-regexp.directive";
import { AuthenticationInterceptorService } from "./interceptors/authentication-interceptor.service";
import { EncryptionInterceptor } from "./interceptors/encryption-interceptor.service";
import { FpDatePipe } from "./pipe/fp-date.pipe";
import { KeysPipe } from "./pipe/keys.pipe";
import { TranslateStatusPipe } from "./pipe/translate-status.pipe";

const PIPES = [KeysPipe, FpDatePipe, TranslateStatusPipe];
@NgModule({
  declarations: [...PIPES, ValidatorRegExpDirective, AutofocusDirective],
  imports: [CommonModule],
  exports: [...PIPES, ValidatorRegExpDirective, AutofocusDirective],
  providers: [
    ...PIPES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptionInterceptor,
      multi: true,
    },
    CurrencyPipe,
  ],
})
export class CoreModule {}
