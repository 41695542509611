import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { Cipher } from "@avaldigitallabs/adl-commons-lib-e2e-frontend-cypher";
import { environment } from "@environment";
import { Observable, from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {
  private cipher: Cipher | null = null;

  private shouldEncrypt(req: HttpRequest<any>): boolean {
    return (
      req.params instanceof CustomHttpParams &&
      (req.params.type === "api-encrypted-endpoint" ||
        req.params.type === "api-encrypted-endpoint-custom-token")
    );
  }

  private async initializeCipher(): Promise<Cipher | null> {
    if (this.cipher) {
      return this.cipher;
    }
    const data = await this.getPublicKeyData();
    if (data?.config?.encryption) {
      this.cipher = new Cipher(data.publicKey, data.timestamp);
    }
    return this.cipher;
  }

  private async getPublicKeyData(): Promise<any> {
    let data = JSON.parse(sessionStorage.getItem("publicKey"));
    if (!data) {
      const urlApiCommons = `${environment.api_key.services.back_url}${environment.endpoints.encryptedRequest.init}`;
      const bodyKey = { timestamp: Date.now() };

      const response = await fetch(
        `${urlApiCommons}${environment.endpoints.encryptedRequest.specific.publicKey}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(bodyKey),
        },
      );

      data = await response.json();
      sessionStorage.setItem("publicKey", JSON.stringify(data));
    }
    return data;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.shouldEncrypt(req)) {
      return next.handle(req);
    }

    if (
      (req.method === "POST" ||
        req.method === "PUT" ||
        req.method === "PATCH") &&
      req.body
    ) {
      return from(this.encryptBody(req.body)).pipe(
        switchMap((encryptedBody) => {
          const authReq = req.clone({ body: encryptedBody });
          return next.handle(authReq).pipe(
            map((event) => this.handleResponse(event)),
            catchError((error) => from(this.decryptErrorResponse(error))),
          );
        }),
      );
    } else {
      return next.handle(req).pipe(
        map((event) => this.handleResponse(event)),
        catchError((error) => from(this.decryptErrorResponse(error))),
      );
    }
  }

  private async encryptBody(body: any): Promise<any> {
    const cipher = await this.initializeCipher();
    if (cipher) {
      return cipher.encryptedObjectToSend(body);
    }
    return body;
  }

  private handleResponse(event: HttpEvent<any>): HttpEvent<any> {
    if (event instanceof HttpResponse && event.body) {
      const data = JSON.parse(sessionStorage.getItem("publicKey"));
      if (data?.config?.encryption && event.body.data) {
        if (!this.cipher) {
          this.cipher = new Cipher(data.publicKey, data.timestamp);
        }
        const txt = this.cipher.decryptMessage(event.body.data);
        if (typeof txt === "string") {
          const decryptedBody = JSON.parse(txt);
          return event.clone({ body: decryptedBody });
        } else {
          throw new Error("Error al desencriptar la respuesta");
        }
      }
    }
    return event;
  }

  private async decryptErrorResponse(error: any): Promise<HttpEvent<any>> {
    const data = JSON.parse(sessionStorage.getItem("publicKey"));
    if (data?.config?.encryption && error?.error?.data) {
      if (!this.cipher) {
        this.cipher = new Cipher(data.publicKey, data.timestamp);
      }
      const txt = this.cipher.decryptMessage(error.error.data);
      if (typeof txt === "string") {
        const decryptedErrorBody = JSON.parse(txt);
        error.error = decryptedErrorBody;
        throw error;
      } else {
        throw new Error("Error al desencriptar la respuesta de error");
      }
    }
    throw error;
  }
}
