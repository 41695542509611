<fp-card-body [template]="'secondTemplate'" [typeDashboard]="true" [exit]="true" backOption="true" class="fp-top-up"
    [actionType]="'logout'" urlBack="/transactions/recharge">
    <ng-template>
        <div class="fp-purchase-detail-container">
            <form novalidate [formGroup]="formRecharge" (ngSubmit)="openModal()">
                <div class="title_box">
                    <h1 class="fp-h1 top-up title">{{"title" | translate}}</h1>
                </div>

                <div class="fp-card-detail">
                    <div class="fp-card-detail-header">
                        <span>Seleccione un método de pago</span>
                    </div>
                    <div class="fp-card-detail-body">
                        <div class="form-group">
                            <div class="conten-input-document">
                                <ng-container *ngFor="let method of paymentMethods; let itype= index">
                                    <button class="fp-btn-option fp-btn-option-purchase" type="button"
                                        [class.selected-option]="this.formRecharge.controls.paymentMethod.value === method.id"
                                        (click)="choosePaymentMethod(method.id, method.name);">
                                        <span class="method-name-container">
                                            <img [src]="'assets/images/common/' + method.logo" [alt]="method.name"
                                                class="payment-method-name__icon" *ngIf="method.id === 'GOU|PSE'">
                                            {{method.name}}
                                        </span>
                                        <span class="radio-btn-container">
                                            <fp-radiobutton [formControl]="formRecharge.controls['paymentMethod']"
                                                formControlName="paymentMethod" name="paymentMethod" [value]="method.id"
                                                inputId="paymentMethod{{method.id}}">
                                            </fp-radiobutton>
                                        </span>
                                    </button>
                                </ng-container>
                            </div>
                            <div class="space-inputs-small"></div>
                        </div>
                    </div>
                </div>

                <div class="fp-card-detail" *ngIf="showDetail">
                    <div class="fp-card-detail-header">
                        <span>Información de la recarga</span>
                    </div>
                    <div class="fp-card-detail-body">
                        <div class="form-group">
                            <div class="content-input-detail">
                                <span>Cuenta con recarga prepago </span>
                                <span>{{payment.transactionTypeData.account}}</span>
                            </div>
                            <div class="content-input-detail">
                                <span>Medio de pago</span>
                                <span>{{paymentMethod}}</span>
                            </div>
                            <div class="content-input-detail">
                                <span>Valor recarga</span>
                                <span class="total-amount">{{payment.totalAmount | number}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fp-card-detail" *ngIf="showDetail">
                    <div class="fp-card-detail-header">
                        <span>{{subtitleMessage | translate }}</span>
                    </div>
                    <div class="fp-card-detail-body">
                        <div class="form-group">

                            {{'payment_documentType' | translate }}
                            <div class="conten-input-document with-margin">
                                <ng-container *ngFor="let type of documentTypes; let itype= index">
                                    <button class="fp-btn-option fp-btn-option-documents" type="button"
                                        [class.selected-option]="this.formRecharge.controls.identificationType.value === type.value"
                                        (click)="selectDocumentType(type.value)">
                                        {{ type.label }}
                                        <span class="radio-btn-container">
                                            <fp-radiobutton [formControl]="formRecharge.controls['identificationType']"
                                                formControlName="identificationType" name="identificationType"
                                                [value]="type.value" inputId="identificationType{{type.value}}">
                                            </fp-radiobutton>
                                        </span>
                                    </button>
                                </ng-container>
                            </div>

                            <fp-input #inputIdNumber class="form-group" labelClass="form-label"
                                nameLabelTranslate="payment_documentId" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true" [inputClass]="'input-large'" typeInput="tel"
                                nameInput="identificationNumber" idInput="identificationNumber" maxlength="10"
                                formControlName="identificationNumber"
                                [inputValidationMessages]="RechargeErrorMessages.documentNumber"
                                [formControl]="formRecharge.controls['identificationNumber']" regexp="^[0-9]{0,10}$"
                                matchRegexp="(\d+)">
                            </fp-input>

                            <!-- Campo para NIT -->
                            <fp-input *ngIf="documentTypeSelected === 'NIT'" class="form-group" labelClass="form-label"
                                [nameLabelTranslate]="labelName" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true"
                                [inputClass]="formRecharge.controls['name'].valid ? 'black-border': 'input-large'"
                                typeInput="text" nameInput="names" idInput="names" formControlName="name"
                                [inputValidationMessages]="RechargeErrorMessages.name" maxlength="60"
                                [formControl]="formRecharge.controls['name']"></fp-input>

                            <!-- Campos para CC y CE -->
                            <fp-input *ngIf="documentTypeSelected !== 'NIT'" class="form-group" labelClass="form-label"
                                [nameLabelTranslate]="'label_first_name'" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true"
                                [inputClass]="formRecharge.controls['firstName'].valid ? 'black-border': 'input-large'"
                                typeInput="text" nameInput="firstName" idInput="firstName" formControlName="firstName"
                                [inputValidationMessages]="RechargeErrorMessages.name" maxlength="60"
                                [formControl]="formRecharge.controls['firstName']"></fp-input>

                            <fp-input *ngIf="documentTypeSelected !== 'NIT'" class="form-group" labelClass="form-label"
                                [nameLabelTranslate]="'label_last_name'" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true"
                                [inputClass]="formRecharge.controls['lastName'].valid ? 'black-border': 'input-large'"
                                typeInput="text" nameInput="lastName" idInput="lastName" formControlName="lastName"
                                [inputValidationMessages]="RechargeErrorMessages.name" maxlength="60"
                                [formControl]="formRecharge.controls['lastName']"></fp-input>

                            <fp-input #inputPhone class="form-group" styleClass="form-group" labelClass="form-label"
                                [nameLabelTranslate]="labelPhone" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true"
                                [inputClass]="formRecharge.controls['phone'].valid ? 'black-border': 'input-large'"
                                typeInput="tel" nameInput="phone" idInput="phone" maxlength="10" formControlName="phone"
                                [inputValidationMessages]="RechargeErrorMessages.phone"
                                [formControl]="formRecharge.controls['phone']" regexp="[0123456789]" matchRegexp="(\d+)"
                                [viewMessageError]="viewMessageError"
                                (onInput)="inputEvent($event, inputPhone)"></fp-input>

                            <fp-input #inputEmail class="form-group" labelClass="form-label"
                                nameLabelTranslate="payment_email" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true" maxlength="70"
                                [inputClass]="formRecharge.controls['email'].valid ? 'black-border': 'input-large'"
                                typeInput="email" nameInput="email" idInput="email" formControlName="email"
                                [inputValidationMessages]="RechargeErrorMessages.email"
                                [formControl]="formRecharge.controls['email']" regexp="^[A-Za-z0-9-+@_\-\.]+$"
                                matchRegexp="([a-zA-Z0-9-+_]+[@.\-]*)">
                            </fp-input>

                            <fp-input #inputConfirmEmail class="form-group" labelClass="form-label"
                                nameLabelTranslate="payment_confirm_email" viewSpaceInput="true"
                                [viewErrorsInput]="true" [viewIconErrorInput]="true" maxlength="70"
                                [inputClass]="formRecharge.controls['confirmEmail'].valid ? 'black-border': 'input-large'"
                                typeInput="confirmEmail" nameInput="confirmEmail" idInput="confirmEmail"
                                formControlName="confirmEmail"
                                [inputValidationMessages]="RechargeErrorMessages.confirmEmail" (paste)="onPaste($event)"
                                [formControl]="formRecharge.controls['confirmEmail']" regexp="^[A-Za-z0-9-+@_\-\.]+$"
                                matchRegexp="([a-zA-Z0-9-+_]+[@.\-]*)">
                            </fp-input>

                            <fp-input #inputAutocomplete class="form-group" styleClass="form-group"
                                labelClass="form-label" [nameLabelTranslate]="'payment_city' | translate"
                                [placeholder]="'select_city' | translate" viewSpaceInput="true" [viewErrorsInput]="true"
                                [viewIconErrorInput]="true" [inputClass]="inputClass" [typeInput]="'text'"
                                nameInput="inputAutocomplete" idInput="inputAutocomplete" formControlName="city"
                                [inputValidationMessages]="RechargeErrorMessages.cities" matAutocomplete="true"
                                [formControl]="formRecharge.controls['city']" regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\-,-. ]+$"
                                matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\-,-. ])" [matchOnInput]="false" [list]="cities"
                                (onBlur)="inputOnBlur()" (onRestInput)="onRestInput()">
                            </fp-input>

                            <fp-input class="form-group" styleClass="form-group" labelClass="form-label"
                                [nameLabelTranslate]="'payment_address' | translate" viewSpaceInput="true"
                                [viewErrorsInput]="true" [viewIconErrorInput]="true" inputClass="input-large"
                                [typeInput]="'text'" nameInput="inputOtherAdrress" idInput="inputOtherAdrress"
                                formControlName="address" [inputValidationMessages]="RechargeErrorMessages.address"
                                [formControl]="formRecharge.controls['address']"
                                regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-#-(-)-,-.-_ ]+$" [minlength]="3" maxlength="255"
                                matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ\-\/\-0-9-#-(-)-,-.-_ ])" [matchOnInput]="false">
                            </fp-input>

                            <div class="row-terms" *ngIf="showPolicySection">
                                <fp-checkbox chkboxId="policy" name="policy" inputId="policy"
                                    [formControl]="formRecharge.controls['policy']" binary="true">
                                </fp-checkbox>
                                <a *ngIf="urlDataPolicy" target="_blank" [href]="urlDataPolicy | translate"
                                    class="checkbox-obligatori padding">
                                    {{ "policy_accept.message1" | translate }}
                                    <u class="fp-btn-link" id="link-termins-conditions">
                                        {{ "policy_accept.policy_authorize" | translate }}
                                    </u>
                                    {{ "policy_accept.message2" | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div #btnContinue class=" form-group-submit">
                    <div class="fp-container-btn submit-btn">
                        <button class="fp-btn-primary" type="submit" [disabled]="formRecharge.invalid || sending">
                            {{"payment_btn" | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
</fp-card-body>


<fp-gou-redirect-modal [btnMessage]="'redirecto_modal.btnMessage' | translate"
    [description]="'redirecto_modal.description' | translate" [title]="'redirecto_modal.title' | translate"
    (itemClickActive)="modalAction($event)"></fp-gou-redirect-modal>

<ng-template #notify>
    <fp-snack-message [title]="'error_title' | translate" (snackMessageEvent)="closeMessage()"
        [description]="'error_same_transaction' | translate : {'transactionId': transactionId}" type="error">
    </fp-snack-message>
</ng-template>