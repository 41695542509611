import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AddressModule } from "./components/address/address.module";
import { AutomaticMovementsModule } from "./components/automatic-movements/automatic-movements.module";
import { FpCardModule } from "./components/card/card.module";
import { CheckboxModule } from "./components/checkbox/checkbox.module";
import { DatepickerModule } from "./components/datepicker/datepicker.module";
import { InfoMessageModule } from "./components/info-message/info-message.module";
import { InputModule } from "./components/input/input.module";
import { ItemListEmptyModule } from "./components/item-list-empty/item-list-empty.module";
import { ItemListMovementModule } from "./components/item-list-movement/item-list-movement.module";
import { ItemListTransactionsModule } from "./components/item-list-transactions/item-list-transactions.module";
import { ItemListVehicleModule } from "./components/item-list-vehicle/item-list-vehicle.module";
import { LoaderModule } from "./components/loader/loader.module";
import { ModalModule } from "./components/modal/modal.module";
import { NavMenuModule } from "./components/nav-menu/nav-menu.module";
import { OtpPasswordModule } from "./components/otp-password/otp-password.module";
import { PageMessageModule } from "./components/page-message/page-message.module";
import { PaymentOptionsModule } from "./components/payment-options/payment-options.module";
import { RadioButtonModule } from "./components/radiobutton/radiobutton.module";
import { SnackMessageModule } from "./components/snack-message/snack-message.module";

const FP_MODULES = [
  FpCardModule,
  CheckboxModule,
  InputModule,
  RadioButtonModule,
  OtpPasswordModule,
  ModalModule,
  PageMessageModule,
  LoaderModule,
  SnackMessageModule,
  AddressModule,
  NavMenuModule,
  InfoMessageModule,
  ItemListVehicleModule,
  ItemListMovementModule,
  ItemListEmptyModule,
  AutomaticMovementsModule,
  PaymentOptionsModule,
  DatepickerModule,
  ItemListTransactionsModule,
];

const COMPONENTS = [];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ...FP_MODULES],
  exports: [...COMPONENTS, ...FP_MODULES],
  declarations: [...COMPONENTS],
  providers: [],
})
export class ThemeModule {}
