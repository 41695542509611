<fp-card-body [typeDashboard]="true" class="fp-top-up" oneColumn="true" backOption="true" [template]="'secondTemplate'"
    exit="true" headerType="secondary" showfooterBallsRight="false" [actionType]="'logout'" urlBack="/home">
    <ng-template #tplRef>
        <div class="fp-purchase-detail-container">
            <div class="form-container">
                <form novalidate [formGroup]="formTopUp" *ngIf="accounts.length > 0">
                    <div class="title_box">
                        <h1 class="fp-h1 top-up title">{{ "title" | translate }}</h1>
                        <h2 class="fp-body-one subtitle"> {{"subtitleRechargePage" | translate }}</h2>
                    </div>
                    <div class="fp-card-detail">
                        <div class="select-content">
                            <label class="form-label" for="accountSelect">
                                {{ 'account' | translate }}</label>
                            <mat-form-field appearance="fill">
                                <mat-select #accountSelect formControlName="account"
                                    panelClass="fp-address-select">
                                    <ng-container *ngFor="let account of accounts">

                                        <mat-option *ngIf="account.statusAccount" class="option-select"
                                            [value]="account.accountId">
                                            {{ "accountWith" | translate }}
                                            {{ (account.accountType === "PRE" ? "prepaidRecharge" : "automaticRecharge") | translate }}
                                            {{ account.accountId }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <img class="select-icon" src="assets/images/select/icon.svg" alt="arrow">
                            </mat-form-field>
                        </div>
                        <fp-input #inputAmount class="form-group input-vehicle" labelClass="form-label"
                            [label]="'min_amount' | translate:{minAmount:minAmount | currency:'COP':'': '1.0-0'}"
                            [autoFocus]="false" viewSpaceInput="true" [viewErrorsInput]="true"
                            [viewIconErrorInput]="true" inputClass="input-large" typeInput="text" nameInput="amount"
                            idInput="amount" [formControl]="formTopUp.controls['amount']"
                            [inputValidationMessages]="TopUpErrorMessages.error" [currencyMode]="true"
                            (onBlur)="validateAmount($event.target.value)"
                            (onInput)="validateAmountTag($event.target.value)"
                            [valueTranslate]="minAmount | currency:'COP':'': '1.0-0'" regexp="^[0-9\.\$ ]+$"
                            matchRegexp="([0-9\.\$ ]*)">
                        </fp-input>
                    </div>

                    <div #btnContinue class=" form-group-submit">
                        <div class="fp-container-btn submit-btn">
                            <button class="fp-btn-primary" (click)="createTransaction()"
                                [disabled]="formTopUp.invalid || viewMessageError">
                                <span>{{ "btn_continue" | translate}}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</fp-card-body>
