import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ICreateTransaction } from "../../models/transaction.interface";
import { CreateTransactionsFeatureName } from "../state/create-transaction.state";

export const CreateTransactionRootSelector =
  createFeatureSelector<ICreateTransaction>(CreateTransactionsFeatureName);

export const selectCreateTransaction = createSelector(
  CreateTransactionRootSelector,
  (state: ICreateTransaction) => state,
);
