<fp-card-body [template]="'secondTemplate'" headerType="secondary" close="true" (actionClose)="goBack()">
  <ng-template #tplRef>
    <div class="container">
      <div class="box">
        <div
          [class]="'icon-message icon-message--' + (statusPaymentValidated ? statusPaymentValidated.status : 'skeleton')">
          <img [src]="statusIcons[statusPaymentValidated?.statusProvider]" alt="Icon message" class="icon-message__icon"
            *ngIf="statusPaymentValidated">
        </div>
        <div class="title-message" [ngClass]="{'title-message--skeleton': !statusPaymentValidated}">
          <span class="title-message__text" *ngIf="statusPaymentValidated">
            {{ titles[statusPaymentValidated?.statusProvider] | translate }}
          </span>
        </div>
        <div class="date-message" [ngClass]="{'date-message--skeleton': !statusPaymentValidated}">
          <span class="date-message__text" *ngIf="statusPaymentValidated">
            {{ statusPaymentValidated?.createdDate | date: 'dd MMMM yyyy / hh:mm a' }}
          </span>
        </div>
        <div class="content-message" [ngClass]="{ 'content-message--skeleton': !statusPaymentValidated }">
          <ng-container *ngIf="statusPaymentValidated">
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Estado' | translate }}</div>
              <div class="content-message-row-item" [ngClass]="{
                          'content-message-row-item--approved': (statusPaymentValidated?.status | translateStatus) === 'Aprobado',
                          'content-message-row-item--pending': (statusPaymentValidated?.status | translateStatus) === 'Pendiente',
                          'content-message-row-item--error': (statusPaymentValidated?.status | translateStatus) === 'Rechazado'
                        }">
                {{ statusPaymentValidated?.status | translateStatus | titlecase }}
              </div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Referencia' | translate }}</div>
              <div class="content-message-row-item">{{ statusPaymentValidated?.id }}</div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Medio de pago' | translate }}</div>
              <div class="content-message-row-item">

                <img [src]="getBrandImage()" alt="Brand card" class="content-message-row-item__brand">

                <span *ngIf="statusPaymentValidated.maskedCardNumber">
                  ****{{statusPaymentValidated.maskedCardNumber}}
                </span>

              </div>
            </div>
            <div class="divider"></div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Descripción' | translate }}</div>
              <div class="content-message-row-item">{{ statusPaymentValidated?.description }}</div>
            </div>
            <div class="content-message-row"
              *ngIf="statusPaymentValidated?.statusProvider !== statusTransactions.APPROVED && statusPaymentValidated?.statusProvider !== statusTransactions.PENDING">
              <div class="content-message-row-item">{{ 'Motivo del rechazo' | translate }}</div>
              <div class="content-message-row-item">{{ statusPaymentValidated?.statusMessage }}</div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Cuenta FacilPass' | translate }}</div>
              <div class="content-message-row-item">{{ statusPaymentValidated?.transactionTypeData.account }}</div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Valor de la recarga' | translate }}</div>
              <div class="content-message-row-item">${{ statusPaymentValidated?.baseAmount | number }}</div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Costo de la transacción' | translate }}</div>
              <div class="content-message-row-item">${{ statusPaymentValidated?.cost | number }}</div>
            </div>
            <div class="divider"></div>
            <div class="content-message-row">
              <div class="content-message-row-item">{{ 'Total' | translate }}</div>
              <div class="content-message-row-item--amount">${{ statusPaymentValidated?.totalAmount | number }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!statusPaymentValidated">
            <div class="content-message-row">
              <div class="content-message-row-item-skeleton"></div>
              <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item-skeleton"></div>
              <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item-skeleton"></div>
              <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
            </div>
            <div class="content-message-row">
              <div class="content-message-row-item-skeleton"></div>
              <div class="content-message-row-item-skeleton content-message-row-item-skeleton--small"></div>
            </div>
          </ng-container>
        </div>
        <button class="button-message" [ngClass]="{ 'button-message--skeleton': !statusPaymentValidated }"
          (click)="goBack()">
          <span class="button-message__text" *ngIf="statusPaymentValidated">
            {{ (statusPaymentValidated?.statusProvider === statusTransactions.APPROVED ||
            statusPaymentValidated?.statusProvider === statusTransactions.PENDING
            ? 'Volver al inicio'
            : 'Reintentar') | translate
            }}
          </span>
        </button>
      </div>
    </div>
  </ng-template>
</fp-card-body>

<ng-template #snackbarMsg>
  <fp-snack-message class="fp-snack-message-login" (snackMessageEvent)="closeMessage()"
    [description]="'Por favor, espere unos minutos para poder usar el método de pago en nuestra plataforma. Estamos validándolo.' | translate"
    [type]="alertsTypes.WARNING">
  </fp-snack-message>
</ng-template>