import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import {
  CardTransactions,
  CardTransactionsRequest,
} from "../models/card-transactions";
import {
  ICreateTransactionRequest,
  IUpdateTransactionRequest,
  IUpdateTransactionResponse,
  IValidatePayment,
} from "../models/transaction.interface";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  /**
   * Constructor
   *
   * @param http hhtp Client
   */
  constructor(private http: HttpClient) {}
  getTransactionReceipt(transactionId: number | string): Observable<any> {
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.recepit.init +
        environment.endpoints.recepit.specific.transactionId +
        transactionId,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token"),
      },
    );
  }

  createTransaction(request: ICreateTransactionRequest) {
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.payments_gateway.init +
        environment.endpoints.payments_gateway.specific.transaction,
      request,
      {
        params: new CustomHttpParams("api-encrypted-endpoint"),
      },
    );
  }

  updateTransaction(
    request: IUpdateTransactionRequest,
    paymentId: string,
  ): Observable<IUpdateTransactionResponse> {
    return this.http.put(
      environment.api_key.services.back_url +
        environment.endpoints.payments_gateway.init +
        environment.endpoints.payments_gateway.specific.updatePayment +
        `/${paymentId}`,
      request,
      {
        params: new CustomHttpParams("api-encrypted-endpoint-custom-token"),
      },
    );
  }

  validatePayment(paymentId: string) {
    const request: IValidatePayment = {
      idPaymentProvider: "GOU",
      responseData: `id=${paymentId}`,
    };
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.payments_gateway.init +
        environment.endpoints.payments_gateway.specific.paymentValidation,
      request,
      {
        params: new CustomHttpParams("api-encrypted-endpoint-custom-token"),
      },
    );
  }

  async getTransactionsByStatus(
    request: CardTransactionsRequest,
  ): Promise<Observable<CardTransactions>> {
    let url =
      environment.api_key.services.back_url +
      environment.endpoints.payments_gateway.init +
      environment.endpoints.payments_gateway.specific.getTransactions +
      `?account=${request.account}&limit=${request.limit}`;

    // Agregas el parámetro lastEvaluatedKey solo si existe
    if (request.lastEvaluatedKey) {
      url += `&key=${request.lastEvaluatedKey}`;
    }

    return this.http.get<CardTransactions>(url, {
      params: new CustomHttpParams("api-gateway-vehicles-v2"),
    });
  }
}
