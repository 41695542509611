import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IPages } from "@app/models/pages";
import { environment } from "@environment";
import { AuthenticationService } from "@singup/services/authentication.service";
import { IVehicleInformation } from "@vehicles/models/vehicle";
import { saveAs } from "file-saver";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { take } from "rxjs/operators";
import { IResponseActiveTAG, ITagActivation } from "../models/tag-activation";
import { ActivationTagService } from "./activation-tag.service";
import { StorageService } from "./storage.service";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class CommonsService {
  timePause: number = 2000;
  /**
   * Constructor
   *
   * @param authenticationService AuthenticationService
   * @param storageService StorageService
   * @param router Router
   * @param http Http
   * @param vehiclesFacade VehiclesFacade
   * @param tagFacade TagFacade
   * @param appFacade
   * @param activationTagService ActivationTagService
   * @param recaptchaV3Service
   */
  constructor(
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private router: Router,
    private http: HttpClient,
    private appFacade: AppFacade,
    private activationTagService: ActivationTagService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}
  /**
   * Logout Method
   *
   * @param flag
   * @param callService
   */
  async logOut(flag = true, callService = true): Promise<any> {
    if (callService) {
      await this.authenticationService.signOut();
    }
    localStorage.clear();
    await this.storageService.removeAll();
    this.appFacade.resetDataStore();
    if (flag) {
      this.router.navigate(["signin/login"]);
    }
  }

  /**
   * Call tagActivation Service
   *
   * @param tagActivationData tagActivationData
   */
  async callTagActivationService(
    tagActivationData: ITagActivation,
  ): Promise<IResponseActiveTAG> {
    return this.activationTagService
      .postTagActivation(tagActivationData)
      .toPromise();
  }
  /**
   * Verify state to return value on guard
   *
   * @param info pages
   * @param stateUrl url
   * @param path path to redirect
   */
  async verifyPagesProcess(
    info: IPages,
    stateUrl: string,
    path: string,
  ): Promise<boolean> {
    let existUrl = false;
    if (info.pages) {
      info.pages.forEach((page) => {
        if (`/${path}/${page.active}` === stateUrl) {
          existUrl = true;
        }
      });
      if (existUrl === false) {
        this.router.navigate([`/home`]);
      }
      return existUrl;
    } else {
      this.router.navigate(["/home"]);
      return false;
    }
  }
  async verifyPagesSignProcess(
    info: IPages,
    stateUrl: string,
    path: string,
  ): Promise<boolean> {
    let existUrl = false;
    if (info.pages) {
      info.pages.forEach((page) => {
        if (`/${path}/${page.active}` === stateUrl) {
          existUrl = true;
        }
      });
      if (existUrl === false) {
        this.router.navigate([
          `/${path}/${info.pages[info.pages.length - 1].active}`,
        ]);
      }
      return existUrl;
    } else {
      this.router.navigate([`/${path}`]);
      return false;
    }
  }
  /**
   * get Documents to Download
   *
   * @param urls urls
   */
  async getDocumentDownload(urls: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    try {
      const name = urls.split("pdf/")[1];
      const resp: any = await this.http
        .get(urls, {
          headers,
          responseType: "blob" as "json",
        })
        .toPromise();
      const blob = new Blob([resp], {
        type: "application/pdf;charset=utf-8",
      });
      saveAs(blob, name);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Generate promise for each url
   *
   * @param urls
   */
  async generatorPromiseDownload(urls: string[]) {
    let documentsDownloadCopy = JSON.parse(JSON.stringify(urls));
    const documents = [];
    documents.push(
      new Promise((resolve, reject) => {
        resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
      }),
    );
    if (urls.length > 1) {
      documentsDownloadCopy = documentsDownloadCopy.splice(1);
      documents.push(
        new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
          }, 2000);
        }),
      );
    }
    const resolvePromises = await Promise.all(documents);

    return this.compareValues(resolvePromises);
  }

  /**
   * Compare answers to all promises
   *
   * @param downloads
   */
  compareValues(downloads: boolean[]) {
    let index: number = 0;
    for (const download of downloads) {
      download === false ? (index = index - 1) : (index = index + 1);
    }
    return index === -downloads.length ? false : true;
  }

  /**
   * Redirect to principal dashboard administration
   */
  redirectToHome(): void {
    this.router.navigate(["/home"]);
  }
  /**
   * Redirect to tag with Vehicle Information
   *
   * @param vehicle vehicle Information
   * @param url
   */
  redirectToTagWithVehicleInformation(
    vehicle: IVehicleInformation,
    url: string = "/tag/buy-tag",
  ): void {
    const navigationExtras: NavigationExtras = {
      state: {
        ...vehicle,
      },
    };
    this.router.navigate([url], navigationExtras);
  }
  /**
   * Navigate ti url
   *
   * @param url
   * @param urlSegment
   */
  navigate(urlSegment: string): void {
    this.router.navigate([urlSegment]);
  }
  /**
   * get Token Information
   */
  async getTokenInfo(): Promise<any> {
    return this.authenticationService.getUserData();
  }
  /**
   * get Ng Chapcha Info
   *
   * @param attemps intentos
   */
  async getTokenNgCaptcha(attemps: number = 1): Promise<any> {
    let token = sessionStorage.getItem("captcha");
    if (attemps < 5) {
      try {
        token = await this.recaptchaV3Service
          .execute("importantAction")
          .pipe(take(1))
          .toPromise();
        sessionStorage.setItem("captcha", token);
      } catch (error) {
        await this.getTokenNgCaptcha(attemps + 1);
      }
    }
  }
  /**
   * getAccountId from storage service
   */
  async getAccountIdRegisteredSingup(): Promise<any> {
    return this.storageService.getItem("accountId");
  }
  async setAttempsErrorServices(name: string): Promise<any> {
    const attemps =
      (await this.storageService.getItem("attempsServices" + name)) === null
        ? 1
        : Number(await this.storageService.getItem("attempsServices" + name)) +
          1;
    await this.storageService.setItem(
      "attempsServices" + name,
      attemps.toString(),
    );
  }
  async getAttempsErrorServices(name: string): Promise<number> {
    return Number(await this.storageService.getItem("attempsServices" + name));
  }
}
