import { createAction, props } from "@ngrx/store";
import {
  ICreateTransaction,
  ICreateTransactionRequest,
} from "../../models/transaction.interface";

export const enum TypeActions {
  createTransactionLoadingString = "[CREATE TRANSACTION] Loading data",
  createTransactionLoadedString = "[CREATE TRANSACTION] Succesfull data loaded",
  createTransactionErrorString = "[CREATE TRANSACTION] error response",
}

export const loadPaymentData = createAction(
  TypeActions.createTransactionLoadingString,
  (data: ICreateTransactionRequest) => ({ data }),
);

export const paymentDataLoad = createAction(
  TypeActions.createTransactionLoadedString,
  (data: ICreateTransaction) => ({
    data,
  }),
);

export const clearPaymentData = createAction("[Payment] Clear Payment Data");
