<fp-card-body [template]="'secondTemplate'" close="true" (actionClose)="actionClose($event)">
    <ng-template>
        <div class="text-center">
            <img class="icon-check" src="assets/images/register/icon-check.svg" alt="Logo check">

            <div class="payment-options">
                <h1 class="fp-h1 welcome-title">
                    {{ 'title' | translate }}
                    <br class="welcome-separator">
                </h1>
                <div class="fp-page-space"></div>
                
            </div>

            <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue" (click)="actionClose($event)">
            <span class="btn-content-text">
                {{ 'button_text' | translate }}
            </span>
        </button>
        </div>
    </ng-template>
</fp-card-body>